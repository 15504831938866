<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini_variant"
      :expand-on-hover="mini_variant"
      :permanent="$vuetify.breakpoint.smAndUp"
      app dark
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{userProfile.name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{userProfile.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>

        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group prepend-icon="mdi-ticket" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Activities'"></v-list-item-title>
            </v-list-item-content>            
          </template>
          <v-list-item v-for="(item, idx) in categories" :key="idx" link :to="'/activities/'+item.code">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>          
        </v-list-group>
        <v-list-group prepend-icon="mdi-database-settings" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Master Data'"></v-list-item-title>
            </v-list-item-content>            
          </template>
          <v-list-item link to="/recommended">
            <v-list-item-title>Recommended Spots</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/vendors">
            <v-list-item-title>Vendors</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/includes">
            <v-list-item-title>Includes</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/documents">
            <v-list-item-title>Documents</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/area">
            <v-list-item-title>Area/Propinsi</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/config">
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item>

        </v-list-group>

        <v-list-group prepend-icon="mdi-chart-areaspline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Reports'"></v-list-item-title>
            </v-list-item-content>            
          </template>
          <v-list-item link to="/users">
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/spotbyadminperiod">
            <v-list-item-title>Spot By Admin Period</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/rawdata">
            <v-list-item-title>Raw Data</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/rptdailycost">
            <v-list-item-title>Sales Cost & Revenue</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-ticket-percent" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Vouchers'"></v-list-item-title>
            </v-list-item-content>            
          </template>
          <v-list-item link to="/vouchers">
            <v-list-item-title>Master Voucher</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/vouchercampaigns">
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/sendvouchers">
            <v-list-item-title>Give Vouchers</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/voucherissuance">
            <v-list-item-title>Vouchers Issuance</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/externalvouchercodes">
            <v-list-item-title>External Voucher Codes</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item link to="/orders">
          <v-list-item-icon>
            <v-icon>mdi-star</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/broadcast">
          <v-list-item-icon>
            <v-icon>mdi-broadcast</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Broadcast</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/newspromo">
          <v-list-item-icon>
            <v-icon>mdi-newspaper-variant-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>News & Promo</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Adventuraja</v-toolbar-title>
    </v-app-bar>

    <v-main style="background-color:#EBEBEB">
      <!-- <router-view :key="$route.fullPath" style="margin-bottom:100px" /> -->
      <slot style="margin-bottom:100px"></slot>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: true
  }),

  created() {
    this.$store.dispatch('category/getObjs')
    console.log(Object.keys(this.$store.state.profile).length)
    if (Object.keys(this.$store.state.profile).length===0) {
      this.$store.dispatch('getProfile').then(response => {
        console.log(response)
      })
    }
  },

  methods: {
      logout() {
          this.$store.commit('removeToken')
          this.$router.push('/login')
      }
  },

  computed: {
    mini_variant() {
      if (this.$vuetify.breakpoint.smAndUp){
        return this.drawer
      }
      else {
        return false
      }
    },
    categories() {
      return this.$store.state.category.objs
    },
    userProfile() {
        return this.$store.state.profile
    }

  }
};
</script>
