<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view :key="$route.fullPath"></router-view>
    </component>
    
  </v-app>
</template>
<script>
const default_layout = "layout-default"

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout)
    }
  }
}
</script>


<style>
.compact-form {
    transform: scale(0.875);
    transform-origin: left;
}

</style>