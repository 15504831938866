<template>
  <v-container class="home">
    <!-- <v-row>
      <v-col cols="12" class="text-center text-h4">Adventuraja Dashboard
      </v-col>
    </v-row> -->
    <v-row class="pt-3">
      <v-col >
        <v-card style="background-image: linear-gradient(to right, #19E6D9 , #5E7AE9);" class="py-5 px-5 d-flex flex-row">
          <div>
          <span class="white--text text-h4 font-weight-medium">
            {{totalVisitors}}
          </span><br>
          <span class="white--text">
            Total Visitors
          </span>
          </div>
          <v-spacer></v-spacer>
          <div class="py-3">
            <v-icon dark x-large>mdi-account-outline</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col >
        <v-card style="background-image: linear-gradient(to right, #F5509E , #FE7279);" class="py-5 px-5 d-flex flex-row">
          <div>
          <span class="white--text text-h4 font-weight-medium">
            {{androidCount}}
          </span><br>
          <span class="white--text">
            Android
          </span>
          </div>
          <v-spacer></v-spacer>
          <div class="py-3">
            <v-icon dark x-large>mdi-google-play</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col >
        <v-card style="background-image: linear-gradient(to right, #3DD99C , #3BB4B6);" class="py-5 px-5 d-flex flex-row">
          <div>
          <span class="white--text text-h4 font-weight-medium">
            {{iosCount}}
          </span><br>
          <span class="white--text">
            iPhone
          </span>
          </div>
          <v-spacer></v-spacer>
          <div class="py-3">
            <v-icon dark x-large>mdi-apple-ios</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col >
        <v-card style="background-image: linear-gradient(to right, #FFCB43 , #FF8A57);" class="py-5 px-5 d-flex flex-row">
          <div>
          <span class="white--text text-h4 font-weight-medium">
            {{totalOrders}}
          </span><br>
          <span class="white--text">
            Total Orders
          </span>
          </div>
          <v-spacer></v-spacer>
          <div class="py-3">
            <v-icon dark x-large>mdi-sale-outline</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col >
        <v-card style="background-image: linear-gradient(to right, #27CB43 , #3A8A57);" class="py-5 px-5 d-flex flex-row">
          <div>
          <span class="white--text text-h4 font-weight-medium">
            {{totalSales}}
          </span><br>
          <span class="white--text">
            Total Sales (Rp)
          </span>
          </div>
          <v-spacer></v-spacer>
          <div class="py-3">
            <v-icon dark x-large>mdi-currency-usd</v-icon>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="12" class="mb-0 px-2">
        <v-card class="pa-1">
          <line-chart :chart-data="datacollection" :options="chartOptions" :height="250" v-if="loaded"></line-chart>
        </v-card>
      </v-col>
      <v-col md="4" sm="12" class="px-2">
        <v-card class="pa-1">
          <line-chart :chart-data="datacollection2" :options="chartOptions2" :height="250" v-if="loaded2"></line-chart>
        </v-card>
      </v-col>
      <v-col md="4" sm="12" class="px-2">
        <v-card class="pa-1">
          <line-chart :chart-data="datacollection2a" :options="chartOptions2a" :height="250" v-if="loaded2a"></line-chart>
        </v-card>
      </v-col>
    </v-row>    

    <v-row>
      <v-col md="4" sm="12" class="px-2">
        <v-card class="pa-1">
          <line-chart :chart-data="datacollection4" :options="chartOptions4" :height="300" v-if="loaded4"></line-chart>
        </v-card>
      </v-col>
      <v-col md="4" sm="12" class="px-2">
        <v-card class="pa-1">
          <bar-chart :chart-data="datacollection3" :options="chartOptions3" :height="300" v-if="loaded3"></bar-chart>
        </v-card>
      </v-col>
      <v-col md="4" sm="12" class="px-2">
        <v-card class="pa-2">
          <horizontal-bar-chart :chart-data="datacollection5" :options="chartOptions5" :height="300" v-if="loaded5"></horizontal-bar-chart>
        </v-card>
      </v-col>
    </v-row>    
  </v-container>
</template>
<script>
import LineChart from "../charts/Line.js";
import BarChart from "../charts/Bar.js";
import HorizontalBarChart from "../charts/HorizontalBar.js";
import moment from 'moment'

export default {
  name: "Dashboard",
  components: {
    LineChart,
    BarChart,
    HorizontalBarChart
  },
  data() {
    return {
      loaded: false,
      loaded2: false,
      loaded3: false,
      loaded4: false,
      loaded5: false,
      loaded2a: false,
      totalVisitors: 0,
      androidCount: 0,
      iosCount: 0,
      totalOrders: 0,
      totalSales: 0,
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Monthly Orders",
            borderColor: "#3e95cd",
            fill: true,
          },
        ],
      },
      datacollection2: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Daily Active Users (DAU)",
            borderColor: "#37ca6b",
            fill: true,
          },
        ],
      },
      datacollection2a: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Monthly Active Users (MAU)",
            borderColor: "#FFB74D",
            fill: true,
          },
        ],
      },

      datacollection3: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Active",
            // backgroundColor: "#36a2eb",
            backgroundColor: "#9AD0F5",
            borderColor: "#36a2eb",
            borderWidth: 2,
            // barThickness: 15
            // fill: true,
          },
          {
            data: [],
            label: "Inactive",
            backgroundColor: "#FFE0E6",
            borderColor: "#ff6384",
            borderWidth: 2,
            // fill: true,
          },
        ],
      },
      datacollection4: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Monthly Orders",
            borderColor: "#3e95cd",
            fill: true,
          },
        ],
      },
      datacollection5: {
        labels: [],
        datasets: [
        {
          data: [],
          // label: element._id,
          backgroundColor: "#FFB74D",
          borderColor: "#FF8901",
          borderWidth: 2,
          barThickness: 15
          // fill: true,
        }

        ],
      },

      chartOptions: {
        title: {
            display: true,
            text: 'Monthly Orders',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        // onClick: (event, clickedElements) => {
        // }
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }           
        
      },
      chartOptions2: {
        title: {
            display: true,
            text: 'Daily Active Users (DAU)',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        // onClick: (event, clickedElements) => {
        // }
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }           
      },
      chartOptions2a: {
        title: {
            display: true,
            text: 'Monthly Active Users (MAU)',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        // onClick: (event, clickedElements) => {
        // }
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }           
      },
      chartOptions3: {
        title: {
            display: true,
            text: 'Number of Spots',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: true,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]

        }     
        // onClick: (event, clickedElements) => {
        // }
      },
      chartOptions4: {
        title: {
            display: true,
            text: 'Monthly Sales (Juta Rupiah)',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }       
      },
      chartOptions5: {
        indexAxis: 'y',
        title: {
            display: true,
            text: 'Top 10 Spots (Last 30 days)',
            fontSize: 16
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 13,
        },
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]

        }     
        // onClick: (event, clickedElements) => {
        // }
      },
    };
  },
  async mounted () {
    this.loaded = false
    let res = await this.$store.dispatch('reports/getObjs', 'reports/getmonthlyorders');
    console.log(res)
    this.datacollection.labels = this.getLabels2a(res)
    this.datacollection.datasets[0].data = this.getData(res)
    this.loaded = true

    this.loaded2 = false
    let res2 = await this.$store.dispatch('reports/getObjs', 'reports/getvisitsbyuniquedevice');
    this.datacollection2.labels = this.getLabels(res2)
    this.datacollection2.datasets[0].data = this.getData(res2)
    this.loaded2 = true

    this.loaded2a = false
    let res2a = await this.$store.dispatch('reports/getObjs', 'reports/getvisitsbyuniquedevicepermonth');
    this.datacollection2a.labels = this.getLabels2a(res2a)
    this.datacollection2a.datasets[0].data = this.getData(res2a)
    this.loaded2a = true

    this.loaded3 = false
    let res3 = await this.$store.dispatch('reports/getObjs', 'reports/gettotalspotsbycategory');
    console.log(res3)
    this.datacollection3.labels = this.getLabels2(res3)
    this.datacollection3.datasets[0].data = this.getDataActiveCount(res3)
    this.datacollection3.datasets[1].data = this.getDataInactiveCount(res3)
    this.loaded3 = true

    this.loaded4 = false
    let res4 = await this.$store.dispatch('reports/getObjs', 'reports/getmonthlysales');
    this.datacollection4.labels = this.getLabels2a(res4)
    this.datacollection4.datasets[0].data = this.getData2(res4)
    this.loaded4 = true

    this.loaded5 = false
    let res5 = await this.$store.dispatch('reports/getObjs', 'reports/gettop10spots');
    res5.sort(function (a, b) {
      return b.count-a.count;
    });

    this.datacollection5.labels = this.getLabels2(res5)
    this.datacollection5.datasets[0].data = this.getData(res5)
    this.loaded5 = true    

    this.totalVisitors = await this.$store.dispatch('reports/getObjs', 'reports/getvisitorcount');
    let devicePlatforms = await this.$store.dispatch('reports/getObjs', 'reports/getdeviceplatformcount');
    this.androidCount = devicePlatforms.find(key=>key._id=="Android").count
    this.iosCount = devicePlatforms.find(key=>key._id=="iOS").count
    const orders = await this.$store.dispatch('reports/getObjs', 'reports/gettotalorders');
    this.totalOrders = orders.length;
    const totalSales = orders.reduce((acc, o) => acc + parseInt(o.total_price), 0)
    this.totalSales = (totalSales/1000000).toFixed(1) + " jt"


    // this.$store.dispatch('reports/getVisitsByDate').then(res => {
    //   // console.log(res)
    //   this.datacollection.labels = this.getLabels(res)
    //   this.datacollection.datasets[0].data = this.getData(res)
    //   this.loaded = true
    // })
    // this.$store.dispatch('reports/getVisitsByDate').then(res => {
    //   // console.log(res)
    //   this.datacollection.labels = this.getLabels(res)
    //   this.datacollection.datasets[0].data = this.getData(res)
    //   this.loaded = true
    // })
    // this.datacollection = userlist
  },  
  methods: {
    getLabels(res) {
      let doc = []
      res.forEach(element => {
        doc.push(moment(element._id).format('D-MMM'))
      });
      return doc
    },
    getLabels2a(res) {
      let doc = []
      res.forEach(element => {
        doc.push(moment(element._id).format('MMM-YY'))
      });
      return doc
    },
    getData(res) {
      let doc = []
      res.forEach(element => {
        doc.push(element.count)
      });
      return doc
    },
    getData2(res) {
      let doc = []
      res.forEach(element => {
        doc.push((Number(element.totalSales)/1000000).toFixed(2))
      });
      return doc
    },
    getLabels2(res) {
      let doc = []
      res.forEach(element => {
        doc.push(element._id)
      });
      return doc
    },
    getDataActiveCount(res) {
      let doc = []
      res.forEach(element => {
        doc.push(element.activeCount)
      });
      return doc
    },
    getDataInactiveCount(res) {
      let doc = []
      res.forEach(element => {
        doc.push(element.totalCount-element.activeCount)
      });
      console.log(doc)
      return doc
    },
  }
};
</script>


<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
@media (min-width: 1400px) {
  .container {
      max-width: 1350px!important;
  }
}
</style>