export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
    },
    actions: {
        getObjs({rootState, commit}){
            let url = rootState.config.apiHost + "categories/"
            console.log(url)
            fetch (url, {method: 'get', headers: rootState.apiheaders})
            .then (response => response.json())
            .then (response => {
                commit('setObjs', response)
            })            
        },        
    },
}