export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
    },
    actions: {
        getObjs({rootState, commit}, vendorCode){
            return new Promise((resolve, reject) => {
                let url
                if (vendorCode){
                    url = rootState.config.apiHost + "orders/vendor/" + vendorCode
                }
                else {
                    url = rootState.config.apiHost + "orders/"
                }

                console.log(url)
                fetch (url, {method: 'get', headers: {'Authorization': 'Bearer '+ rootState.token}})
                .then (response => response.json())
                .then (response => {
                    commit('setObjs', response)
                    resolve(response)
                }, err => {
                    reject(err)
                })            
            })
        },
        postObj({rootState}, pload){
            return new Promise((resolve, reject) => {
                var url = rootState.config.apiHost + 'updateorderstatus/'
                console.log(pload)

                fetch(url, {method: 'post', body:JSON.stringify(pload), headers: {'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.result)
                        resolve(response)
                    else
                        reject(response)
                }, error => {
                    reject(error)
                });
            })
        },
        reschedule({rootState}, pload){
            return new Promise((resolve, reject) => {
                var url = rootState.config.apiHost + 'reschedule/'
                console.log(pload)

                fetch(url, {method: 'post', body:JSON.stringify(pload), headers: {'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.result)
                        resolve(response)
                    else
                        reject(response)
                }, error => {
                    reject(error)
                });
            })
        },
        updateOrderCost({rootState}, pload){
            return new Promise((resolve, reject) => {
                var url = rootState.config.apiHost + 'updateordercost/'
                console.log(pload)

                fetch(url, {method: 'post', body:JSON.stringify(pload), headers: {'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.result)
                        resolve(response)
                    else
                        reject(response)
                }, error => {
                    reject(error)
                });
            })
        },
        manualBooking({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "manualbooking"
                fetch (url, {method: 'post', body: JSON.stringify(payload), headers: {'Content-Type': 'application/json', 'Authorization': 'bearer ' + rootState.token}})
                .then (response => response.json())
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })            
            })
        },

    },
}