<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini_variant"
      :expand-on-hover="mini_variant"
      :permanent="$vuetify.breakpoint.smAndUp"
      app dark
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{userProfile.name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{userProfile.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>

        <v-list-item link to="/vendordashboard">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/vendoractivities/vendor">
          <v-list-item-icon>
            <v-icon>mdi-database-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Activities</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/vendororders">
          <v-list-item-icon>
            <v-icon>mdi-star</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>

        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Adventuraja</v-toolbar-title>
    </v-app-bar>

    <v-main style="background-color:#EBEBEB">
      <!-- <router-view :key="$route.fullPath" style="margin-bottom:100px" /> -->
      <slot style="margin-bottom:100px"></slot>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: true
  }),

  created() {
    this.$store.dispatch('category/getObjs')
    console.log(Object.keys(this.$store.state.profile).length)
    if (Object.keys(this.$store.state.profile).length===0) {
      this.$store.dispatch('getProfile').then(response => {
        console.log(response)
      })
    }
  },

  methods: {
      logout() {
          this.$store.commit('removeToken')
          this.$router.push('/login')
      }
  },

  computed: {
    mini_variant() {
      if (this.$vuetify.breakpoint.smAndUp){
        return this.drawer
      }
      else {
        return false
      }
    },
    categories() {
      return this.$store.state.category.objs
    },
    userProfile() {
        return this.$store.state.profile
    }

  }
};
</script>
