import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: Home
  },
  {
    path: '/activities/:category',
    name: 'Activities',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Activities.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/vendors',
    name: 'Vendors',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Vendor.vue')
  },
  {
    path: '/includes',
    name: 'Includes',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Include.vue')
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Document.vue')
  },
  {
    path: '/recommended',
    name: 'Recommended',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Recommended.vue')
  },
  {
    path: '/area',
    name: 'Area',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Area.vue')
  },
  {
    path: '/config',
    name: 'Config',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Config.vue')
  },
  {
    path: '/newspromo',
    name: 'NewsPromo',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/NewsPromo.vue')
  },
  {
    path: '/broadcast',
    name: 'Broadcast',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Broadcast.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/User.vue')
  },
  {
    path: '/spotbyadminperiod',
    name: 'SpotByAdminPeriod',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/SpotByAdminPeriod.vue')
  },
  {
    path: '/rawdata',
    name: 'Raw Data',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/RawData.vue')
  },
  {
    path: '/rptdailycost',
    name: 'Sales Cost & Revenue',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/RptDailyCost.vue')
  },
  {
    path: '/vouchers',
    name: 'Master Voucher',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/Vouchers.vue')
  },
  {
    path: '/vouchercampaigns',
    name: 'Voucher Campaigns',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/VoucherCampaign.vue')
  },
  {
    path: '/voucherissuance',
    name: 'Voucher Issuance',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/VoucherIssuance.vue')
  },
  {
    path: '/sendvouchers',
    name: 'Send Vouchers',
    meta: {
      requiresAuth: true,
      type: 'admin'
    },
    component: () => import('../views/SendVouchers.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresVisitor: true,
      layout: 'layout-login'
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/vendororders',
    name: 'Orders',
    meta: {
      requiresAuth: true,
      layout: 'layout-vendor',
      type: 'vendor'
    },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/vendordashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
      layout: 'layout-vendor',
      type: 'vendor'
    },
    component: () => import('../views/HomeVendor.vue')
  },
  {
    path: '/vendoractivities/:category',
    name: 'Activities',
    meta: {
      requiresAuth: true,
      layout: 'layout-vendor',
      type: 'vendor'
    },
    component: () => import('../views/Activities.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const profile = await store.dispatch('getProfile')
    console.log(profile.type, to.meta.type)

    if (!store.getters.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (profile.type==to.meta.type){
        next()
      }
      else (
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      )
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
