import Vue from 'vue'
import Vuex from 'vuex'
import activity from './modules/activity'
import location from './modules/location'
import category from './modules/category'
import vendor from './modules/vendor'
import gallery from './modules/gallery'
import order from './modules/order'
import include from './modules/include'
import document from './modules/document'
import newspromo from './modules/newspromo'
import user from './modules/user'
import area from './modules/area'
import configs from './modules/configs'
import reports from './modules/reports'
import holiday from './modules/holiday'
import broadcast from './modules/broadcast'
import voucher from './modules/voucher'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,  
  modules: {
    activity,
    location,
    category,
    vendor,
    gallery,
    order,
    include,
    document,
    newspromo,
    user,
    area,
    configs,
    reports,
    holiday,
    broadcast,
    voucher
  },
  state: {
    config: {
      backend: process.env.VUE_APP_ROOT_API + '/',
      apiHost: process.env.VUE_APP_ROOT_API + '/api/',
      mediaHost: process.env.VUE_APP_ROOT_API + '/gallery/',
    },
    token: localStorage.getItem('adm_access_token') || null,
    profile: {}
  },
  getters: {
    loggedIn(state) {
      // console.log(state)
      // return true
      if (state.token === null || state.token === "undefined") return false
      else return true
    },
  },  
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('adm_access_token', token)
    },
    setProfile(state, payload) {
      state.profile = payload
    },
    removeToken(state) {
      state.token = null
      localStorage.removeItem('adm_access_token')
    },
  },
  actions: {
    login({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        const url = state.config.apiHost + 'auth/adminlogin'

        fetch(url, {method: 'post', body:JSON.stringify(payload), headers: {'Content-Type': 'application/json'}})
        .then(response => response.json())
        .then(response => {
            commit('setToken', response.token)
            commit('setProfile', response.profile)
            console.log('',response)
            resolve(response)
        }, error => {
            reject(error)
        });
      })
    },
    getProfile({state, commit}) {
      return new Promise((resolve, reject) => {
        const url = state.config.apiHost + 'auth/adminprofile'
        fetch(url, {method: 'get', headers: {'Authorization': 'bearer ' + state.token}})
        .then(response => response.json())
        .then(response => {
            console.log(response)
            commit('setProfile', response)
            resolve(response)
        }, error => {
            reject(error)
        });
      })
    },

  },
})
