export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
        admins: [],
    },
    mutations: {
        setObj(state, pload) {
            state.obj = pload
        },
        setObjs(state, pload) {
            state.objs = pload
        },
        setAdmins(state, pload) {
            state.admins = pload
        },

    },
    actions: {
        getObj({rootState, commit}, _id){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "activities/" + _id
                fetch (url, {method: 'get', headers: rootState.apiheaders})
                .then (response => response.json())
                .then (response => {
                    commit('setObj', response)
                    resolve(response)
                }, error => {
                    reject(error)
                })            
            })
        },
        getObjs({rootState, commit}){
            let url = rootState.config.apiHost + "activities/"
            console.log(url)
            fetch (url, {method: 'get', headers: rootState.apiheaders})
            .then (response => response.json())
            .then (response => {
                commit('setObjs', response)
            })            
        },
        getObjsByCategory({rootState, commit}, category){
            return new Promise((resolve) => {
                let url = rootState.config.apiHost + "activities/adventure2/" + category + "/newest"
                console.log(url)
                fetch (url, {method: 'get'})
                .then (response => response.json())
                .then (response => {
                    commit('setObjs', response)
                    resolve(response)
                }, err => {
                    resolve(err)
                })
            })
        },
        getHighlights({rootState}){
            return new Promise((resolve) => {
                let url = rootState.config.apiHost + "activities/get/highlight"
                console.log(url)
                fetch (url, {method: 'get'})
                .then (response => response.json())
                .then (response => {
                    resolve(response)
                }, err => {
                    resolve(err)
                })
            })
        },
        updateHighlight({rootState}, payload){
            return new Promise((resolve) => {
                let url = rootState.config.apiHost + "activities/highlight"
                console.log(payload)
                fetch (url, {method: 'post', body:JSON.stringify(payload), headers: {'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => response.json())
                .then (response => {
                    resolve(response)
                }, err => {
                    resolve(err)
                })
            })
        },
        postObj({rootState}, pload){
            return new Promise((resolve, reject) => {
                var url = rootState.config.apiHost + 'activities/'
                console.log(pload)

                fetch(url, {method: 'post', body:JSON.stringify(pload), headers: {'Content-Type': 'application/json'}})
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.result)
                        resolve(response)
                    else
                        reject(response)
                }, error => {
                    reject(error)
                });
            })
        },
        getAdmins({rootState, commit}){
            return new Promise((resolve) => {
                let url = rootState.config.apiHost + "admins/"
                console.log(url)
                fetch (url, {method: 'get'})
                .then (response => response.json())
                .then (response => {
                    commit('setAdmins', response)
                    resolve(response)
                }, err => {
                    resolve(err)
                })
            })
        },
        getBookedCount({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "getbookedcount"
                console.log(url)
                fetch (url, {method: 'post', body: JSON.stringify(payload), headers: {'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => response.json())
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })            
            })
        },   
        getVendorActivities({rootState, commit}, vendorCode){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "activities/getbyvendor/" + vendorCode
                console.log(url)
                fetch (url, {method: 'get', headers: rootState.apiheaders})
                .then (response => response.json())
                .then (response => {
                    commit('setObjs', response)
                    resolve(response)
                }, error => {
                    reject(error)
                })            
            })
        },   
    },
}