import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLazyload from 'vue-lazyload'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import "vue-lazy-youtube-video/dist/style.simplified.css";
import LayoutLogin from '@/components/layouts/login' 
import LayoutDefault from '@/components/layouts/default' 
import LayoutVendor from '@/components/layouts/vendor' 
import VueCurrencyFilter from 'vue-currency-filter'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.use(VueLazyload)
Vue.component('layout-login', LayoutLogin)
Vue.component('layout-default', LayoutDefault)
Vue.component('layout-vendor', LayoutVendor)
Vue.use(VueCurrencyFilter,
  {
    symbol : 'Rp',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  })
  
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  VueUploadMultipleImage,
  render: h => h(App)
}).$mount('#app')
